<template>
	<div class="main">
		<!-- <Enterprise v-if="$userRole(['Enterprise'])"/> -->
		<!-- <General v-else-if="$userRole(['GeneralAdmin', 'SalesMan'])"/> -->
		<!-- 我的申请 -->
		<my-apply v-if="$userRole(['Subordinate'])"></my-apply>
		<!-- 最近到期 -->
		<expire-license v-else-if="!!!$userRole(['Subordinate'])"></expire-license>
		<!-- 无系统操作权限 -->
		<el-empty :image-size="200" v-else :description="t('system.no_permission')" />
	</div>
</template>

<script setup>
// import Enterprise from './components/Enterprise'
// import General from './components/General'
import MyApply from './components/MyApply'
import ExpireLicense from './components/ExpireLicense'
import { inject } from 'vue'
const t = inject('t')
</script>

<style lang="scss" scoped>
.nopermission {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
</style>
