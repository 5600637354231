<template>
  <!-- 最近到期License -->
	<span>{{ t('dashboard.expired_license') }}</span>
	<custem-table
    :tableInfo="tableInfo"
    :tableLoading="tableLoading"
    @getList="getExpireLicense"></custem-table>
</template>

<script setup>
import { onMounted, reactive, getCurrentInstance, inject, ref } from 'vue'
import { AuthManagement } from '@/utils/api.js'
const t = inject('t')
const { proxy } = getCurrentInstance()
const tableLoading = ref(false)
const tableInfo = reactive({
	header: [
		{ // 所属企业
			label: t('dashboard.enterprise'),
			fixed: true,
			minWidth: '280px',
			hidden: !proxy.$userRole(['SuperAdmin', 'GeneralAdmin', 'SalesMan']),
			custem: (data) => {
				if (data.project)
					return data.project.enterprise.name
				else return '-'
			}
		},
		{ // 项目
			label: t('dashboard.project'),
			minWidth: '320px',
			custem: (data) => {
				if (data.project)
					return data.project.name
				else return '-'
			}
		},
		{ // 销售人员
			label: t('dashboard.sales'),
			minWidth: '320px',
			hidden: !!!proxy.$userRole(['SuperAdmin', 'GeneralAdmin']),
			custem: (data) => {
				if (data.order)
					return data.order.sales.name
				else return '-'
			}
		},
		{ // 数量
			label: t('dashboard.quantity'),
			minWidth: '110px',
			sortable: true,
			sortBy: (data) => {
				return data.content.hosts
			},
			custem: (data) => {
				if (data.content)
					return `${data.content.hosts}${t('unit.ge')}`
				else return '-'
			}
		},
		{ // 订单编号
			label: t('dashboard.order_id'),
			minWidth: '320px',
			sortable: true,
			sortBy: (data) => {
				return data.order.code
			},
			custem: (data) => {
				if (data.order && data.order.code)
					return data.order.code
				else return '-'
			}
		},
		{ // 创建时间
			label: t('table.creation_time'),
			key: 'created_at',
			minWidth: '180px',
			sortable: true,
			sortBy: (data) => {
				return data.created_at
			}
		},
		{ // 到期时间
			label: t('table.expiration_time'),
			key: 'expired_at',
			minWidth: '180px',
			sortable: true,
			sortBy: (data) => {
				return data.expired_at
			}
		}
	]
})

// 最近到期License
const getExpireLicense = async (page) => {
  tableLoading.value = true
  try {
    const res = await AuthManagement.AssignmentList({ filter_lately_expire: true, ...page })
		tableInfo.data = res.items
		tableInfo.totalPage = res.total
		tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}
onMounted(() => {
	getExpireLicense(1)
})
</script>