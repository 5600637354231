<template>
	{{ t('assign.my_application') }}
	<custem-table
    :tableInfo="tableInfo"
    :tableLoading="tableLoading"
    @getList="getExpireLicense"></custem-table>
</template>

<script setup>
import { onMounted, reactive, getCurrentInstance, inject, ref } from 'vue'
import { AuthManagement } from '@/utils/api.js'

const t = inject('t')
const { proxy } = getCurrentInstance()
const tableLoading = ref(false)
const tableInfo = reactive({
	header: [
		{ // 申请编号
			key: 'id',
			label: t('assign.application_id'),
			sortable: true,
			minWidth: '320px',
			fixed: true
		},
		{ // 所属项目
			key: 'expired_at',
			label: t('assign.project'),
			minWidth: '280px',
			custem: (data) => {
        return data.project ? data.project.name : '-'
			}
		},
		{ // 申请数量
			key: 'content.value',
			label: t('assign.application_count'),
			minWidth: '140px',
			sortable: true,
			sortBy: (data) => {
				return data.content.hosts
			},
			custem: (data) => {
        return data.content ? `${proxy.$numberToCurrencyNo(data.content.hosts)} ${t('unit.ge')}` : '-'
			}
		},
		{ // 创建日期
			key: 'created_at',
			label: t('table.creation_time'),
			minWidth: '180px',
			sortable: true,
			sortBy: (data) => {
				return data.created_at
			}
		},
		{ // 状态
			key: 'status',
			label: t('system.status'),
			minWidth: '100px',
			sortable: true,
			sortBy: (data) => {
				return data.status
			},
			custem: (data) => {
        return data ? proxy.$formatApplicatState(data.status, 'html') : '-'
			}
		}
	]
})

// 我的申请列表
const getExpireLicense = async (page) => {
  tableLoading.value = true
  try {
    const res = await AuthManagement.ApplicationList({ ...page })
		tableInfo.data = res.items
		tableInfo.totalPage = res.total
		tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

onMounted(() => {
	getExpireLicense()
})
</script>